<!-- 勘探安装数据 -->
<template>
    <div class="box">
      <div class="user">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>用户信息</div>
        </div>
        <div class="usercon">
            <div class="con-1">
                <el-select v-model="value" placeholder="请选择">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-button type="primary" @click="xz">选择</el-button>
            </div>
            <div class="con">
                <div class="conli">
                    <div>用户编号：   {{userlist.customer_code  }}</div>
                    <div>用户姓名：   {{userlist.customer_name  }}</div>
                    <div>手机号：    {{userlist.mobile  }}</div>
                </div>
                <div class="conli">
                    <div>性别：    {{userlist.sex  }}</div>
                    <div>证件类型：    {{userlist.legal_certificate_type  }}</div>
                    <div>身份证：    {{userlist.certificate_no }}</div>
                </div>
                <div class="conli">
                    <div>代理商编号：    {{userlist.agent_code  }}</div>
                    <div>代理商名称：    {{userlist.agent_name  }}</div>
                    <div>所属分公司：    {{userlist.company_name  }}</div>
                </div>
                <div class="conli">
                    <div>用户地址：    {{userlist.city_name  }}</div>
                    
                </div>
            </div>
        </div>
      </div>
      <div class="kant">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>勘探基本信息</div>
        </div>
        <div class="con">
            <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        style="position: relative"
        label-width="150px"
      >
        <el-row>
          <el-col :span="6"
            ><el-form-item label="勘探编号">
              <el-input
                v-model="formInline.explorate_code"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="勘探时间">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="电站类型">
              <el-input
                v-model="formInline.powerstation_type"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="勘探方式">
              <el-input
                v-model="formInline.explorate_type"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="勘探人员">
              <el-input
                v-model="formInline.explorater_name"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="勘探人手机号">
              <el-input
                v-model="formInline.explorater_phone"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="不拆装图片迁徙">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="合作类型">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="组件规格">
              <el-input
                v-model="formInline.component_specification"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="单片功率（w）">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="方案块数">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="方案总功率">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="系统功率（w）">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="安装编号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="创建账号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="基准位置">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="勘探位置">
              <el-input
                v-model="formInline.explorate_area"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          
        </el-row>
      </el-form>
        </div>
       
      </div>
      <div class="home">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>房屋照片</div>
        </div>
        <div class="home-img">
            <div class="home-title">正大门照片</div>
            <div class="imglist">
                <div class="img-tit">正大门照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                    <div class="upload">
                        <el-upload
  action="#"
  list-type="picture-card"
  :auto-upload="false">
    <i slot="default" class="el-icon-plus"></i>
    <div slot="file" slot-scope="{file}">
      <img
        class="el-upload-list__item-thumbnail"
        :src="file.url" alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
</el-upload>
<el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
                    </div>
                </div>
            </div>
            <div class="imglist">
                <div class="img-tit">其他照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="home-img">
            <div class="home-title">主房房屋全景照片（可看清房屋层数）</div>
            <div class="imglist">
                <div class="img-tit">房屋全景照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                </div>
            </div>
            <div class="imglist">
                <div class="img-tit">其他照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                </div>
            </div>
        </div>
        
       
      </div>
      <div class="home">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>其他照片信息</div>
        </div>
        <div class="home-img">
            <!-- <div class="home-title">正大门照片</div> -->
            <div class="imglist">
                <div class="img-tit">方位角照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                    <div class="upload">
                        <el-upload
  action="#"
  list-type="picture-card"
  :auto-upload="false">
    <i slot="default" class="el-icon-plus"></i>
    <div slot="file" slot-scope="{file}">
      <img
        class="el-upload-list__item-thumbnail"
        :src="file.url" alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
</el-upload>
<el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
                    </div>
                </div>
            </div>
            <div class="imglist">
                <div class="img-tit">其他照片：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                </div>
            </div>
            <div class="imglist">
                <div class="img-tit">方位角角度：</div>
                <div class="imm">
                    <div class="immde">
                        <img src="../../assets/images/normal_u39.svg" alt="">
                        <div>实例照片</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="faan">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>方案信息</div>
        </div>
        <div class="faan-wd">屋顶1</div>
        <div class="zd">自动生成方案</div>
        <div class="con">
            <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        style="position: relative"
        label-width="150px"
      >
        <el-row>
          <el-col :span="6"
            ><el-form-item label="屋顶类型">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="屋顶形状">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="安装方式">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="6"
            ><el-form-item label="长/a（米）">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="宽/b（米）">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6"
            ><el-form-item label="勘探人手机号">
              <el-input
                v-model="formInline.user"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
        </div>
        <div class="zd">组排排布方案</div>
        <div class="pb">
            <div>屋顶1 组件排布图</div>
            <img src="../../assets/images/normal_u71.png" alt="">
        </div>
        <div class="tip">
          <div>说明：</div>
          <div>1. 本屋顶可安装56块660W光伏组件</div>
          <div>2. 组件尺寸：2404 * 1323 * 35 (mm)</div>
          <div>3. 本图仅供参考，如现场实际情况与图纸不符，请及时与技术人员联系，安装方式以技术人员最终确认为准。</div>
        </div>
        <div class="zd">现场照片</div>
        <div class="xcimg">
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="xc">
            <div style="color: #555555;margin-bottom: 20px;">东面照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="qt">
            <div style="color: #555555;margin-bottom: 20px;">其他照片：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
        <div class="zd">现场视频</div>
        <div class="qt">
            <div style="color: #555555;margin-bottom: 20px;">周围环境及屋面视频：</div>
            <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
      </div>
      <div class="shej">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>设计图纸</div>
        </div>
        <div class="sj-tit">设计图纸文件:</div>
        <div class="wj-list">
          <div style="border: 1px solid #d7d7d7;width: 139px;height: 139px;">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
               >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
          </div>
          <div style="color:  #aaaaaa;font-size: 13px;">请上传平面图文件</div>
        </div>
      </div>
      <div class="bz">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>备注</div>
        </div>
        <div class="bz-con">
          <textarea name="" id="" style="width: 90%;height: 107px;margin-left: 20px;margin-top: 20px;border: 1px solid #d7d7d7;"></textarea>
        </div>
      </div>
      <div class="jl">
        <div class="title">
            <img src="../../assets/images/normal_u10.svg" alt="">
            <div>勘探操作记录</div>
        </div>
        <div class="jllist">
          <div>流程状态</div>
          <div>部门/角色</div>
          <div>操纵人</div>
          <div>备注</div>
          <div>操作时间</div>
          <div>操作图片</div>
        </div>
        <div class="jllist" v-for="(item,i) in jllist" :key="i">
          <div>{{ item.type }}</div>
          <div>{{ item.na }}</div>
          <div>{{ item.name }}</div>
          <div>{{ item.tip }}</div>
          <div>{{ item.time }}</div>
          <div>{{ item.url }}</div>
        </div>
      </div>
      <div class="ok">
        <el-button @click="onsubmit" size="medium" type="primary" style="width: 200px;height: 40px;">确认</el-button>
      </div>
      <el-dialog
        title="请选择客户"
        :visible.sync="dialogVisible"
        width="50%"
        >
       <div class="dia">
        <div class="dia-top">
          <div>客户名称：</div>
          <div>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <el-table
        :data="tableData"
        @selection-change="handleSelectionChange"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'font-size': '16px',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
      <el-table-column type="selection" > </el-table-column>
       
        <el-table-column
          prop="reconnaissance_name"
          label="勘探人员"
          
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="nickname"
          label="客户名称"
          
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="city_name"
          label="用户地址"
         
          align="center"
        >
        </el-table-column>
          </el-table>
          <div class="pagination">
                   <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page.sync="queryInfo.currPage"
                      
                      :page-size="queryInfo.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="count"
                    >
                   </el-pagination>
                </div>
        </div>
       </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="qud">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      handleRemove(file) {
        console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        console.log(file);
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      xz() {
        this.dialogVisible = true
        this.$http.post("houseCustomer/list",this.queryInfo).then(res => {
          this.tableData = res.data.data.data;
          this.count = res.data.data.count
        });
      },
         // 修改页数大小
         handleSizeChange (val) {
            this.queryInfo.pageSize = val;
            this.getdata()
          },
        // 获取当前页面
        handleCurrentChange (val) {
          this.queryInfo.currPage = val;
          this.getdata()
        },
        getdata(){
          this.$http.post("houseCustomer/list",this.queryInfo).then(res => {
          this.tableData = res.data.data.data;
          
          
        });
        },
        handleSelectionChange(val){
          console.log(val);
          this.val = val
        },
        qud(){
          this.dialogVisible=false
          this.userlist = this.val[0]
          console.log(this.userlist,'ppp');
        },
        onsubmit(){
          this.$http.post("houseExplorateBase/save",this.formInline).then(res => {
            console.log(res);
          });
        }
    },
  
    data() {
      return {
        val:{},
        userlist:{},
        count:'',
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        tableData:[],
        value: '',
        dialogVisible:false,
        imageUrl: '',
        queryInfo: {
           pageSize: 10,
           pageCount: 1,
           currPage: 1,
           condition: {},

        },
        options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
        value: '',
        formInline: {
          user:"",
          explorate_code: "",
          powerstation_type: "",
          explorate_type:"",
          explorater_name:'',
          explorater_phone:'',
          component_specification:"",
        },
        jllist:[{
          type:'勘探已提交',
          na:'经销商',
          name:'秦祥宇',
          tip:'habanainc',
          time:'2023-02-28 16:07:12',
          url:''
        },{
          type:'勘探已提交',
          na:'经销商',
          name:'秦祥宇',
          tip:'habanainc',
          time:'2023-02-28 16:07:12',
          url:''
        },{
          type:'勘探已提交',
          na:'经销商',
          name:'秦祥宇',
          tip:'habanainc',
          time:'2023-02-28 16:07:12',
          url:''
        }]
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .box {
    .title {
            display: flex;
            font-size: 18px;
            color: #333;
            font-weight: 700;
            border-bottom:1px solid rgba(215, 215, 215, 0.57);
            align-items: center;
            height: 50px;
            padding-left: 20px;
            img {
                margin-right: 10px;
                width: 8px;
                height: 30px;
            }
        }
    .user {
        background-color: #fff;
        
        .usercon {
            box-sizing: border-box;
            padding: 20px 30px;
            .con {
                .conli {
                    display: flex;
                    div {
                        margin-top: 20px;
                        flex: 1;
                    }
                }
            }
        }
    }
    .kant {
        margin-top: 20px;
        background-color: #fff;
        .con {
            margin-top: 20px;

        }
    }
    .home {
        background-color: #fff;
        margin-top: 30px;
        .home-img {
            margin-left: 40px;
            .home-title {
                color: #333;
                border-bottom:1px solid rgba(215, 215, 215, 0.57);
                
                margin-top: 20px;
                font-size: 18px;
                color: #333;
                font-weight: 700;
            }
            .imglist {
                margin-left: 20px;
                .img-tit {
                    color: #555555;
                    font-size: 16px;
                    margin: 20px 0 ;
                }
                .imm {
                   
                    display: flex;
                    .immde {
                        margin-right: 20px;
                        width: 139px;
                        img {
                            width: 139px;
                            height: 139px;
                            border-radius: 5px;
                        }
                        text-align: center;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .faan {
        background-color: #fff;
        margin-top: 20px;
        .faan-wd {
            width: 90%;
            height: 51px;
            padding-left: 20px;
            border-radius: 5px;
            border: 1px solid rgba(129, 211, 248, 0.22);
            background-color: rgba(129, 211, 248, 0.22);
            box-sizing: border-box;
            line-height: 51px;
            margin-top: 30px;
            margin-left: 20px;
        }
        .zd {
            border-bottom: 1px solid rgba(215, 215, 215, 0.57);
            line-height: 50px;
            margin-top: 30px;
            margin-left: 30px;
        }
        .con {
            margin: 20px 0;
        }
        .pb {
          div {
            margin-top: 30px;
            margin-bottom: 30px;
          }
          text-align: center;
          img {
            width: 321px;
            height: 196px;
          }
        }
        .tip {
          margin-top: 30px;
          color:  #333333;
          font-size: 13px;
          margin-left: 30px;
        }
        .xcimg {
          margin-top: 20px;
          margin-left: 60px;
          display: flex;
          flex-wrap: wrap;
          .xc {
            width: 139px;
            margin: 30px;
            
          }
        }
        .qt {
          margin-top: 20px;
          margin-left: 90px;
          .xc {
            width: 139px;
            margin: 30px;
            
          }
        }
    }
    .shej {
      background-color: #fff;
      margin-top: 20px;
      .sj-tit {
        margin-left: 50px;
        margin-top: 20px;
      }
      .wj-list {
        margin-left: 150px;
        margin-top: 20px;
      }
    }
    .bz {
      background-color: #fff;
      margin-top: 20px;
    }
    .jl {
      background-color: #fff;
      margin-top: 20px;
      .jllist {
        display: flex;
        height: 62px;
        line-height: 62px;
        padding: 0 45px;
        div {
          flex: 1;
          color: #7f7f7f;
          font-size: 16px;
        }
      }
    }
    .ok {
      background-color: #fff;
      margin-top: 20px;
      text-align: center;
      height: 80px;
      padding-top: 40px;
    }
  }
  .dia {
    .dia-top {
      display: flex;
      align-items: center;
    }
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 139px;
    height: 139px;
    line-height: 139px;
    text-align: center;
  }
  .avatar {
    width: 139px;
    height: 139px;
    display: block;
  }
  
  </style>